import React from "react"
import { CheckCircleIcon } from '@heroicons/react/solid'

import { StaticImage } from "gatsby-plugin-image"

/* This example requires Tailwind CSS v2.0+ */
const faqs = [
  {
    question: 'Thérapie de la relation',
    answer:
      '',
  },
  {
    question: 'Thérapie de couple',
    answer:
      '',
  },
  {
    question: 'Thérapie au travail',
    answer:
      '',
  },
  {
    question: 'Sexothérapie',
    answer:
      '',
  },
  {
    question: 'Coaching adultes HPi',
    answer:
      '',
  },
  {
    question: 'Workshops',
    answer:
      '',
  },
  {
    question: 'Formation',
    answer:
      '',
  },
  {
    question: 'Mentoring',
    answer:
      '',
  },
  {
    question: 'Supervision',
    answer:
      '',
  }
  // More questions...
]

export default function Section2() {
  return (
    <div className="bg-gray-100">
      <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:py-10 lg:px-8">
        <div className="lg:grid lg:grid-cols-4 lg:gap-8">
          <div className="mt-12 lg:col-span-2 lg:mt-0">
            <h2 className="mt-1 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl lg:text-4xl">Pratiques &amp; Spécialités</h2>
            <p className="mt-4 text-lg text-gray-500">
            </p>
            <StaticImage
                className="w-full rounded-lg shadow-2xl mt-4"
                alt="Text"
                src="../../images/couple.jpg" 
              />
          </div>
          <div className="mt-12 lg:col-span-2 lg:mt-16">
            <dl className="space-y-6">
              {faqs.map((faq) => (
                <div key={faq.question}>
                  
                  <dt className="text-lg font-medium leading-6 text-gray-900">
                    <CheckCircleIcon className="h-5 w-5 text-[#4DB2E0] inline mr-2" aria-hidden="true" />{faq.question}
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">{faq.answer}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}

  