import React from "react"
import { AcademicCapIcon, DesktopComputerIcon, ChatAlt2Icon } from '@heroicons/react/solid'


/* This example requires Tailwind CSS v2.0+ */
export default function Section1bis() {
    return (
      <div className="bg-[#4DB2E0]">
        <div className="mx-auto max-w-7xl py-12 px-4 sm:py-16 sm:px-6 lg:px-8 lg:py-20">
          <div className="mx-auto max-w-4xl text-center">
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-3xl">
            Philippe Lamy reçoit des patients à Lyon et à l'Ile de Ré
            </h2>
            <p className="mt-3 text-xl text-white sm:mt-4">
            Difficultés conjugales, crise de couple, célibat douloureux. <br /><a className="underline" href="/rdv/">Consultation sur RDV de 8h à 20h - en cabinet ou visio</a>.
            </p>
          </div>
          <dl className="mt-10 text-center sm:mx-auto sm:grid sm:max-w-4xl sm:grid-cols-3 sm:gap-8">
            <div className="flex flex-col">
              <dt className="order-2 mt-2 text-lg font-medium leading-6 text-white">Visio consultation</dt>
              <dd className="order-1 text-5xl font-bold tracking-tight text-white"><DesktopComputerIcon className="h-12 w-12 flex-shrink-0 text-white mx-auto" aria-hidden="true" /></dd>
            </div>
            <div className="mt-10 flex flex-col sm:mt-0">
              <dt className="order-2 mt-2 text-lg font-medium leading-6 text-white">Diplomé et expérimenté</dt>
              <dd className="order-1 text-5xl font-bold tracking-tight text-white"><AcademicCapIcon className="h-12 w-12 flex-shrink-0 text-white mx-auto" aria-hidden="true" /></dd>
            </div>
            <div className="mt-10 flex flex-col sm:mt-0">
              <dt className="order-2 mt-2 text-lg font-medium leading-6 text-white">Première consultation offerte</dt>
              <dd className="order-1 text-5xl font-bold tracking-tight text-white"><ChatAlt2Icon className="h-12 w-12 flex-shrink-0 text-white mx-auto" aria-hidden="true" /></dd>
            </div>
          </dl>
        </div>
      </div>
    )
  }
  