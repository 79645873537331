import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import {
  ArrowCircleRightIcon
} from '@heroicons/react/solid'

const features = [
  {
    name: 'Les séances individuelles',
    description:
      "Les séances individuelles ponctuelles sont au prix de 70€ pour 45 minutes (ou 90 € pour une heure) en rendez-vous individuels / RV physiques ou téléconsultations.",
    icon: ArrowCircleRightIcon,
  },
  {
    name: 'Les séances de mentoring',
    description:
      "Les séances de mentoring pour les étudiants sont gratuites (dans la limite des créneaux disponibles). Les séances de supervision sont gratuites pour les membres du réseau Spring-MediCare (dans la limite des créneaux disponibles).",
    icon: ArrowCircleRightIcon,
  },
  {
    name: 'Les cures longue durée',
    description:
      "Les cures longue durée (pack de 10 séances d’une heure, réglées d’avance) sont au prix de 855 €. Ce montant intègre le fil de messagerie permanent décrit plus haut.",
    icon: ArrowCircleRightIcon,
  }
]
/*
const checklist = [
  '45 minutes',
  'No per user fees',
  'Unlimited storage',
  '24/7 support',
  'Cancel any time',
  '14 days free',
]
*/

const tiers = [
  {
    name: 'Séance 45min',
    href: 'https://buy.stripe.com/5kA0254SDaCpeNG6oo',
    priceMonthly: 70,
    description: 'Physique/Téléconsultation',
    includedFeatures: ['Potenti felis, in cras at at ligula nunc.', 'Orci neque eget pellentesque.'],
  },
  {
    name: 'Séance 1h',
    href: 'https://buy.stripe.com/eVadSVacX6m9bBu4gh',
    priceMonthly: 90,
    description: 'Physique/Téléconsultation',
    includedFeatures: [
      'Potenti felis, in cras at at ligula nunc. ',
      'Orci neque eget pellentesque.',
      'Donec mauris sit in eu tincidunt etiam.',
    ],
  },
  {
    name: 'Couple 45min',
    href: 'https://buy.stripe.com/28odSVetd11P9tmdQS',
    priceMonthly: 100,
    description: 'Physique/Téléconsultation',
    includedFeatures: [
      'Potenti felis, in cras at at ligula nunc. ',
      'Orci neque eget pellentesque.',
      'Donec mauris sit in eu tincidunt etiam.',
      'Faucibus volutpat magna.',
    ],
  },
  {
    name: 'Couple 1h',
    href: 'https://buy.stripe.com/fZebKN2Kv7qdcFyeUX',
    priceMonthly: 130,
    description: 'Physique/Téléconsultation',
    includedFeatures: [
      'Potenti felis, in cras at at ligula nunc. ',
      'Orci neque eget pellentesque.',
      'Donec mauris sit in eu tincidunt etiam.',
      'Faucibus volutpat magna.',
      'Id sed tellus in varius quisque.',
      'Risus egestas faucibus.',
      'Risus cursus ullamcorper.',
    ],
  },
]

export default function Section3() {
  return (
    <div className="bg-white">
      <div className="bg-gray-50">
        {/* Header section */}
        <div className="mx-auto max-w-7xl py-8 px-4 sm:py-12 sm:px-6 lg:px-8">
          <div className="text-center">
            <blockquote className="mt-10">
              <div className="mx-auto max-w-3xl text-center text-2xl font-medium leading-9 text-gray-900">
                <p>
                  &ldquo;Je place l’engagement thérapeutique au centre de le relation patient/thérapeute et, selon les cas, je propose des accompagnements sur des durées courtes (thérapies brèves) ou plus longues (cures) dans la limite de mes disponibilités.&rdquo;
                </p>
              </div>
              <footer className="mt-8">
                <div className="md:flex md:items-center md:justify-center">
                  <div className="md:flex-shrink-0">
                    <StaticImage 
                      className="mx-auto h-10 w-10 rounded-full"
                      alt=""
                      src='../../images/Philippe-Lamy-Thérapeute-de-couple.jpg'
                    />
                  </div>
                  <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                    <div className="text-base font-medium text-gray-900">Philippe Lamy</div>

                    <svg className="mx-1 hidden h-5 w-5 text-[#4DB2E0] md:block" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M11 0h3L9 20H6l5-20z" />
                    </svg>

                    <div className="text-base font-medium text-gray-500">Thérapeute de couples, sexothérapeute</div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>

      {/* Split brand panel */}
      <div className="relative bg-white">
        <div className="absolute inset-0" aria-hidden="true">
          <div className="absolute inset-y-0 right-0 w-1/2 bg-gradient-to-r from-cyan-600 to-white" />
        </div>
        <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-2 lg:px-8">
          <div className="bg-white py-4 px-4 sm:py-8 sm:px-6 lg:px-0 lg:pr-8">
            <div className="mx-auto max-w-lg lg:mx-0">
              <h2 className="mt-1 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl lg:text-4xl">Consultation</h2>
              <dl className="mt-12 space-y-10">
                {features.map((feature) => (
                  <div key={feature.name} className="relative">
                    <dt>
                      <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-gradient-to-r from-[#4DB2E0] to-white">
                        <ArrowCircleRightIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg font-medium leading-6 text-gray-900">{feature.name}</p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
                  </div>
                ))}
              </dl>
              <h2 className="mt-1 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl lg:text-4xl pt-10">Horaires</h2>
              <dl className="mt-12 space-y-10">
                  <div className="relative">
                    <dt>
                      <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-gradient-to-r from-[#4DB2E0] to-white">
                        <ArrowCircleRightIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg font-medium leading-6 text-gray-900"></p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">
                    Les consultations ne sont pas remboursées par l’Assurance Maladie, mais – sous toute réserve – certaines assurances complémentaires remboursent tout ou partie du montant des consultations.
                    </dd>
                  </div>
              </dl>
              <dl className="mt-12 space-y-10">
                  <div className="relative">
                    <dt>
                      <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-gradient-to-r from-[#4DB2E0] to-white">
                        <ArrowCircleRightIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg font-medium leading-6 text-gray-900"></p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">
                    Mes horaires sont : 8:00 – 18:00, sur RV uniquement.<br />
              Les RV manqués et non décommandés au moins 48 heures à l’avance resteront dus.
                    </dd>
                  </div>
              </dl>
              <dl className="mt-12 space-y-10">
                  <div className="relative">
                    <dt>
                      <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-gradient-to-r from-[#4DB2E0] to-white">
                        <ArrowCircleRightIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg font-medium leading-6 text-gray-900"></p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">
                    
                    </dd>
                  </div>
              </dl>


              <p className="mt-2 ml-16 text-base text-gray-500">
Nota : Je dispose actuellement de peu de créneaux pour les nouveaux patients mais, en cas d’urgence, je saurai vous trouver un créneau ou je vous recommanderai à un.e thérapeute de mon réseau personnel.
              </p>
            </div>
          </div>
          <div className="bg-gradient-to-r from-[#4DB2E0] to-gray-200 py-8 px-4 sm:py-12 sm:px-6 lg:flex lg:items-center lg:justify-end lg:bg-none lg:px-0 lg:pl-8">
            

            <div className="mx-auto max-w-7xl py-24 px-4 sm:px-6 lg:px-8">
              <div className="sm:align-center sm:flex sm:flex-col">
                <h2 className="mt-1 text-1xl font-bold tracking-tight text-gray-900 sm:text-1xl lg:text-2xl pt-10 mx-auto">Réservez votre consultation en ligne</h2>
                <p className="mt-5 text-xl text-gray-500 sm:text-center">
                  
                </p>
              </div>
              <div className="mt-12 space-y-4 sm:mt-16 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-2">
                {tiers.map((tier) => (
                  <div key={tier.name} className="divide-y divide-gray-200 rounded-lg border border-gray-200 shadow-sm">
                    <div className="p-6">
                      <h2 className="text-lg font-medium leading-6 text-gray-200">{tier.name}</h2>
                      <p className="mt-4 text-sm text-gray-200">{tier.description}</p>
                      <p className="mt-8">
                        <span className="text-4xl font-bold tracking-tight text-gray-900">{tier.priceMonthly}</span>{' '}
                        <span className="text-base font-medium text-gray-200"> €</span>
                      </p>
                      <a
                        href={tier.href}
                        className="mt-8 block w-full rounded-md border border-gray-800 bg-gray-800 py-2 text-center text-sm font-semibold text-white hover:bg-gray-900"
                      >
                        Payer ici
                      </a>
                    </div>
                    
                  </div>
                ))}
              </div>
            </div>


          </div>
        </div>
      </div>

      
    </div>
  )
}
