import React from "react"
import { MailIcon, PhoneIcon } from '@heroicons/react/solid'
import { AtSymbolIcon } from '@heroicons/react/outline'
import {LinkedinSquare} from "@styled-icons/boxicons-logos"
// FacebookSquare, Twitter
/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
export default function Section1() {
  return (
    <div className="bg-white">
        <div className="mx-auto py-12 max-w-7xl sm:px-6 lg:px-8 lg:py-20">
          <div className="lg:flex">

            <div className="flex flex-col lg:w-1/2">

              <div className="mx-4 max-w-lg">
                <h2 className="mt-1 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl lg:text-4xl">Mes coordonnées</h2>
                <p className="mt-3 text-lg leading-6 text-gray-500">
                Trouvez des solutions pour améliorer votre vie en couple.
                </p>
                <dl className="mt-8 text-base text-gray-500">
                  <div>
                    <dt className="sr-only">Adresse</dt>
                    <dd>
                      <p>8 rue de la Ventouse</p>
                      <p>17670 La Couarde sur Mer</p>
                      <p>ÎLE DE RÉ (France)</p>
                    </dd>
                  </div>
                  <div className="mt-6">
                    <dt className="sr-only">Téléphone</dt>
                    <dd className="flex">
                      <PhoneIcon className="h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
                      <span className="ml-3">+33 (0)6 63 35 71 11</span>
                    </dd>
                  </div>
                  <div className="mt-3">
                    <dt className="sr-only">Email</dt>
                    <dd className="flex">
                      <MailIcon className="h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
                      <span className="ml-3">p.lamy<AtSymbolIcon  className="h-5 inline" />spring-medicare.fr </span>
                    </dd>
                  </div>
                  <div className="mt-3">
                    <dt className="sr-only">Email</dt>
                    <dd className="flex">
                      <a href="https://www.linkedin.com/in/philippe-lamy-03760119a/" target="_blank" rel="noreferrer">
                        <LinkedinSquare className="h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        <span className="ml-3">LinkedIn</span>
                      </a>
                    </dd>
                  </div>
                </dl>
                <p className="flex mt-6 text-base text-gray-500">
                  <a
                    href="/blog/"
                    className="mr-4 mt-4 flex items-right justify-center rounded-md border border-transparent bg-[#4DB2E0] hover:bg-[#3991ba] md:px-10 py-4 px-8 text-lg font-medium leading-6 text-white"
                  >
                    Mon blog
                  </a>
                  <a
                    href="/faq/"
                    className="mt-4 flex items-right justify-center rounded-md border border-transparent bg-[#4DB2E0] hover:bg-[#3991ba] md:px-10 py-4 px-8 text-lg font-medium leading-6 text-white"
                  >
                    FAQ
                  </a>
                </p>
              </div>
            </div>

            <div className="flex flex-col pt-4 mx-4 lg:w-1/2 lg:pt-0">
              <h2 className="mt-1 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl lg:text-4xl">Présentation</h2>
              <p className="mx-auto mt-3 max-w-md text-base text-gray-500 sm:text-lg md:mt-5 md:max-w-4xl md:text-xl">
              J’exerce essentiellement en téléconsultation, mais aussi à Lyon et sur l’Île de Ré (au large de La Rochelle) les activités de thérapeute de la relation au travail (burnout, emprise toxique, sociophobie…), thérapeute de couples, sexothérapeute et différentes techniques de pleine conscience. J’anime des groupes en thérapie ou formation dans ces mêmes spécialités.
              </p>
              <p className="mx-auto mt-3 max-w-md text-base text-gray-500 sm:text-lg md:mt-5 md:max-w-4xl md:text-xl">
              Mais mon activité est également l’animation du réseau Spring MediCare. J’œuvre à établir le dialogue entre ses adhérents, écouter leurs attentes et suggestions et à coordonner leurs interactions. Je m’emploie notamment à donner corps aux rencontres, animations, formations et échanges de pratiques entre praticiens.
              </p>
              <p className="flex justify-right mt-6 text-base text-gray-500">
                <a
                  href="/contact/"
                  className="mt-4 flex items-right justify-center rounded-md border border-transparent bg-orange-500 hover:bg-orange-700 md:px-10 py-4 px-8 text-lg font-medium leading-6 text-white"
                >
                  Contactez-moi en cliquant ici
                </a>
              </p>
            </div>
          </div>
        </div>
    </div>
  )
}
  